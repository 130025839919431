import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

const fetchDeviceDashboardData = async (dataa) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.DEVICE_DASHBOARD_DATA.API,
    dataa
  );
  return response;
};

export const useDeviceDashboardData = () => {
  const [serverError, setServerError] = useState(null);

  const mutation = useMutation({
    mutationFn: fetchDeviceDashboardData,
    onSuccess: (response) => {
      if (response.status !== 200) {
        setServerError(response.data.status);
      } else {
        setServerError(null);
      }
    },
  });

  // Function to manually trigger the mutation
  const fetchDashboardData = async (dataa) => {
    try {
      await mutation.mutateAsync(dataa);
    } catch (error) {
      console.error('Error fetching device dashboard data:', error);
    }
  };

  return {
    data: mutation.data ? mutation.data.data.data : null,
    status: mutation.data ? mutation.data.data.status : null,
    responseStatus: mutation.data ? mutation.data.status : null,
    isLoadingDashboardData: mutation.isPending, // Use isPending to track the loading state
    error: mutation.error,
    fetchDashboardData,
    serverError,
    mutate: mutation.mutate,
  };
};
