import React from 'react';
import { TableCell, TableRow, Avatar, Box, Tooltip } from '@mui/material';
import { truncateText } from '@utils';
import { getMessageForStatusOfEdit } from '../../../../../constants/editTrackStatuses';
import { format } from 'date-fns';
import ActionsMenu from './ActionsMenu';

const PreviousTrackRow = ({
  row,
  borderColor,
  actionsMenuProps,
  editTrackInfo,
  isAllowActions,
}) => {
  const editValue = row.original.edit;

  return (
    <TableRow hover>
      {row.getVisibleCells().map((cell, cellIndex) => {
        const cellContent = (
          <TableCell
            key={`prev-${cell.id}`}
            sx={{
              fontSize: '1.4rem !important',
              paddingLeft: cellIndex === 0 ? '5.7rem' : 'inherit',
              paddingRight:
                cellIndex === row.getVisibleCells().length - 1
                  ? '5.7rem'
                  : 'inherit',
              py: editValue === 'none' ? 0 : '1px',
              borderTop:
                editValue !== 'none'
                  ? `1px solid ${borderColor} !important`
                  : 'none',
              borderLeft: cellIndex === 0 ? `1px solid transparent` : 'none',
              borderRight:
                cellIndex === row.getVisibleCells().length - 1
                  ? `1px solid transparent`
                  : 'none',
              position: 'relative',
              ...(cellIndex === 0 && {
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-1px',
                  width: '1px',
                  height: '130%',
                  backgroundColor: borderColor,
                  borderRadius: '4px',
                },
              }),
              ...(cellIndex === row.getVisibleCells().length - 1 && {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  right: '-1px',
                  width: '1px',
                  height: '130%',
                  backgroundColor: borderColor,
                  borderRadius: '4px',
                },
              }),
            }}
          >
            {cell.column.id === 'time_recorded' &&
            row.original.previous_track ? (
              <Box sx={{ whiteSpace: 'nowrap', maxWidth: `${250}px` }}>
                {format(
                  new Date(row.original.previous_track.time_recorded),
                  'HH:mm:ss'
                )}{' '}
                &nbsp;&nbsp;
                {format(
                  new Date(row.original.previous_track.time_recorded),
                  'dd/MM/yy'
                )}
              </Box>
            ) : cell.column.id === 'cover_image' &&
              row.original.previous_track ? (
              <Avatar
                variant="rounded"
                src={
                  row.original.previous_track.cover_image !== '-'
                    ? row.original.previous_track.cover_image
                    : '/no-cover-image.png'
                }
                alt="Previous Cover"
                onError={(event) => (event.target.src = '/no-cover-image.png')}
                sx={{ width: '41px', height: '41px' }}
              />
            ) : cell.column.id === 'title' && row.original.previous_track ? (
              truncateText(row.original.previous_track.title, 50)
            ) : cell.column.id === 'artist' && row.original.previous_track ? (
              truncateText(row.original.previous_track.artist, 45)
            ) : cell.column.id === 'duration' && row.original.previous_track ? (
              typeof row.original.previous_track.duration === 'number' &&
              !isNaN(row.original.previous_track.duration) ? (
                Math.floor(row.original.previous_track.duration / 60000) > 0 ? (
                  `${Math.floor(
                    row.original.previous_track.duration / 60000
                  )}m ${Math.floor(
                    (row.original.previous_track.duration % 60000) / 1000
                  )}s`
                ) : (
                  `${Math.floor(
                    (row.original.previous_track.duration % 60000) / 1000
                  )}s`
                )
              ) : (
                '-'
              )
            ) : cell.column.id === 'genres' && row.original.previous_track ? (
              row.original.previous_track.genres.join(', ')
            ) : cell.column.id === 'isrc' && row.original.previous_track ? (
              row.original.previous_track.isrc
            ) : cell.column.id === 'actions' && row.original.previous_track ? (
              <ActionsMenu
                openAddBeforeTrackPopup={() => {
                  actionsMenuProps.addBeforeTrackHandler();
                }}
                openUndoEditPopup={() => {
                  actionsMenuProps.undoEditHandler();
                }}
                isDisabled={!isAllowActions}
                trackStatus={editValue}
                isPreviousTrack={true}
              />
            ) : null}
          </TableCell>
        );

        // Only wrap with Tooltip if the cell is not an "actions" cell
        return cell.column.id !== 'actions' ? (
          <Tooltip
            key={cell.id}
            title={getMessageForStatusOfEdit(editValue, editTrackInfo)}
            followCursor
            placement="bottom"
            disableHoverListener={editValue === 'none'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#2D323E',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontSize: '1.1rem',
                  borderRadius: '4px',
                },
              },
            }}
          >
            {cellContent}
          </Tooltip>
        ) : (
          cellContent
        );
      })}
    </TableRow>
  );
};

export default PreviousTrackRow;
