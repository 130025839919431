import { useMutation } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box } from '@mui/material';
import { useUser } from '@context/UserContext';
import ActionsPopup from '../ActionsPopup';
import SearchTrack from './SearchTrack';
import { addTrackData } from '@apis';
import { useState } from 'react';
import AddCustomTrackForm from './AddCustomTrackForm';
import TrackItem from './TrackItem';

const AddTrackBeforeAfter = ({
  open,
  handleClose,
  selectedTrack,
  eventId,
  refetchDashboard,
}) => {
  const [isShowAddCustomTrack, setIsShowAddCustomTrack] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [addedCustomTrack, setAddedCustomTrack] = useState({
    title: '',
    artists: '',
  });
  const [addedFoundTrack, setAddedFoundTrack] = useState({
    title: '',
    artists: '',
    coverImage: '',
    trackId: '',
  });

  const auth = getAuth();
  const { user } = useUser();
  const { mutate: addTrackMutate, isPending: isAdding } = useMutation({
    mutationFn: addTrackData,
    onSuccess: (response) => {
      if (response && response.data.status === 'Track added') {
        refetchDashboard();
        handleClose();
      }
    },

    onError: (err) => {
      console.log('ERROR');
    },
  });
  const invalidAddedCustomTrack =
    addedCustomTrack.title === '' || addedCustomTrack.artists === '';
  const shouldDisplayCustomTrack =
    !invalidAddedCustomTrack && !isShowAddCustomTrack && isAddButtonClicked;
  const shouldDisplayFoundTrack =
    addedFoundTrack.title !== '' &&
    addedFoundTrack.artists !== '' &&
    !isShowAddCustomTrack;

  const renderTrackItem = (title, artist, coverImage, timeRecorded) => (
    <Box mt="3.3rem">
      <TrackItem
        trackTypeInfo="New Track"
        title={title}
        artist={artist}
        coverImage={coverImage}
        duration={timeRecorded}
      />
    </Box>
  );

  const handleSubmitAddTrack = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const hasCustomTrack = addedCustomTrack.title !== '';

          const params = {
            userId: user?.user_id,
            accessToken: idToken,

            eventId,
            newTimeRecorded: selectedTrack.newTimeRecorded,
            trackId: hasCustomTrack ? null : addedFoundTrack.trackId,
            customTrack: hasCustomTrack
              ? {
                  title: addedCustomTrack.title,
                  artist: addedCustomTrack.artists,
                }
              : null,
          };

          await addTrackMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };

  const onAddButtonSubmit = () => {
    setIsShowAddCustomTrack(false);
    setIsAddButtonClicked(true);
    setAddedFoundTrack({ title: '', artists: '', coverImage: '', trackId: '' });
  };

  const isDisabledSubmit = () => {
    if (isShowAddCustomTrack) {
      return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
    } else {
      if (isAddButtonClicked) {
        return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
      } else {
        return addedFoundTrack.title === '' || addedFoundTrack.artists === '';
      }
    }
  };

  return (
    <ActionsPopup
      open={open}
      handleClose={() => {
        if (isShowAddCustomTrack) {
          setIsShowAddCustomTrack(false);
        } else {
          setAddedFoundTrack({
            title: '',
            artists: '',
            coverImage: '',
            trackId: '',
          });
          setAddedCustomTrack({ title: '', artists: '' });
          handleClose();
        }
      }}
      handleSubmit={() => {
        if (isShowAddCustomTrack) {
          onAddButtonSubmit();
        } else {
          handleSubmitAddTrack();
        }
      }}
      loading={isAdding}
      headingText={'Add Track'}
      loadingText="Adding..."
      submitBtnText={'Add'}
      disabledSubmit={isDisabledSubmit()}
    >
      {isShowAddCustomTrack ? (
        <AddCustomTrackForm
          addedTrack={addedCustomTrack}
          setAddedTrack={setAddedCustomTrack}
        />
      ) : (
        <SearchTrack
          onClickAddCustomTrack={() => setIsShowAddCustomTrack(true)}
          updateFoundTrack={(track) => {
            setAddedFoundTrack({
              title: track.title,
              artists: track.artists,
              coverImage: track.coverImage,
              trackId: track.trackId,
            });
            setAddedCustomTrack({ title: '', artists: '' });
            setIsAddButtonClicked(false);
          }}
        />
      )}
      <>
        {shouldDisplayCustomTrack &&
          renderTrackItem(
            addedCustomTrack.title,
            addedCustomTrack.artists,
            null,
            selectedTrack.newTimeRecorded
          )}
        {shouldDisplayFoundTrack &&
          renderTrackItem(
            addedFoundTrack.title,
            addedFoundTrack.artists,
            addedFoundTrack.coverImage,
            selectedTrack.newTimeRecorded
          )}
        {!shouldDisplayFoundTrack &&
          !shouldDisplayCustomTrack &&
          renderTrackItem('-', '-', '-', selectedTrack.newTimeRecorded)}
      </>
    </ActionsPopup>
  );
};

export default AddTrackBeforeAfter;
