import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI
import ReactMarkdown from 'react-markdown';
import CustomTooltip from '../Tooltip'; // Keep the existing custom Tooltip

const SwitchButton = styled((props) => (
  <Switch focusVisibleClassName="Mui-focusVisible" disableRipple {...props} />
))(({ theme, isShow }) => ({
  width: 42,
  height: 24,
  padding: 0,
  margin: 0,
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        border: '1px solid #FD8A50',
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    border: `1px solid ${!isShow ? '#757575' : '#FD8A50'}`,
    backgroundColor: 'transparent',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));

export default function ApproveTrackListSwitchBtn({
  tooltipInfo,
  isApprove,
  setIsApprove,
  isDisabled,
  toggleBtnTooltipText,
}) {
  return (
    <>
      <FormGroup sx={{ opacity: !isDisabled ? 1 : '0.4' }}>
        <Tooltip
          title={toggleBtnTooltipText}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'background.tooltip',
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: '11px',
              },
            },
          }}
        >
          <FormControlLabel
            disabled={isDisabled}
            control={
              <SwitchButton
                checked={isApprove}
                onChange={setIsApprove}
                name="checked"
                isShow={isApprove}
              />
            }
            label={
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '1.4rem',
                  color: '#FFFFFF',
                  marginRight: '0.8rem',
                }}
              >
                Approve track-list
              </Typography>
            }
            sx={{ marginRight: 0 }}
            labelPlacement="start"
          />
        </Tooltip>
      </FormGroup>
      <CustomTooltip
        title={
          <Box
            sx={{
              padding: '5px',
              '& ul': {
                margin: 0,
                padding: '0 16px',
              },
              '& li': {
                margin: 0,
                padding: 0,
              },
              '& p': {
                margin: '5px',
                padding: 0,
              },
            }}
          >
            <ReactMarkdown>
              {tooltipInfo.replace(/<br\s*\/?>/g, '  \n')}
            </ReactMarkdown>
          </Box>
        }
      />
    </>
  );
}
