import React, { createContext, useState, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getUser } from '@apis'; // Adjust the import path based on your project structure
import { useMutation } from '@tanstack/react-query';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  // Use useMutation for POST request to get user data
  const mutation = useMutation({
    mutationFn: getUser,
    onSuccess: (data) => {
      setIsLoading(false);
      if (data) {
        setUser(data.data);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('Error fetching user data:', error);
    },
  });

  // Function to fetch user data based on Firebase auth state
  const fetchUser = () => {
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        authUser.getIdToken().then(async (idToken) => {
          try {
            const userData = {
              access_token: idToken,
              email: authUser.email,
            };

            await mutation.mutateAsync(userData);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Optionally handle the error or retry logic here
          }
        });
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        isLoading,
        error: mutation.error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
