import { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';

import Fit from 'react-fit';

import { Button } from '@components';
import ArrowNextIcon from '@assets/svgs/arrow-next-icon.svg';
import ArrowDownIcon from '@assets/svgs/arrow-down-icon.svg';
import { getCalendarDate } from '@utils';
import DashboardBreadcrumb from '../DashboardBreadcrumb';
import { useUser } from '@context/UserContext';

export const dashboardTypes = {
  group: 0,
  device: 1,
  event: 2,
  deviceFromGroup: 3,
};

const DashboardHeader = ({
  dashboardType,
  lastUpdateTime,
  calendarProps,
  filtersProps,
  hasFilterOptions,
  deviceName,
  eventDate,
  hasLoading,
}) => {
  const [currentTime] = useState(new Date().getHours());

  const { user, isLoading } = useUser();

  const {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  } = calendarProps || {};

  const { FILTERS, activeFilter, onClickDateFilters } = filtersProps || {};

  const greetings = useMemo(() => {
    if (currentTime < 12) {
      return 'Good morning';
    }
    if (currentTime < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }, [currentTime]);

  const renderHeadingText = () => {
    if (hasLoading) {
      return <Box height={25} width={250} bgcolor={'white'}></Box>;
    }
    if (dashboardType === dashboardTypes.event) {
      return moment(eventDate).format('Do MMMM YYYY');
    }
    if (
      dashboardType === dashboardTypes.group ||
      dashboardType === dashboardTypes.device
    ) {
      if (user && !isLoading) {
        return `${greetings}, ${user.name.split(' ')[0]}`;
      }

      return '';
    }
    if (dashboardType === dashboardTypes.deviceFromGroup) {
      return deviceName;
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Box mb="0.8rem">
          <DashboardBreadcrumb />
        </Box>
        <Typography variant="h1">{renderHeadingText()}</Typography>
      </Box>

      {hasFilterOptions && (
        <Box className="dashboard-filters-wrapper">
          <Box display="flex" justifyContent="flex-end" mb="40px">
            <Typography variant="caption" color="primary.main">
              Updated last on {lastUpdateTime}
            </Typography>
          </Box>

          <Box className="date-picker-wrapper">
            <div className="dashboard-date-picker">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
                onClick={handleToggleCalendar}
              >
                <div className="date-wrapper">
                  <p>{getCalendarDate(dateRange[0])}</p>
                  <img src={ArrowNextIcon} alt="" />
                  <p>{getCalendarDate(dateRange[1])}</p>
                </div>
                <img
                  src={ArrowDownIcon}
                  alt=""
                  id="calender-icon"
                  className={
                    isShowCalendar ? 'rotate-icon' : 'calendar-arrow-icon'
                  }
                />
              </div>
              {isShowCalendar && (
                <Fit>
                  <div className="calendar-custom-wrapper" ref={calendarRef}>
                    <Calendar
                      selectRange
                      clearIcon={null}
                      maxDate={new Date()}
                      minDate={new Date(MIN_DATE_RANGE)}
                      onChange={onChangeDateRange}
                      value={dateRange}
                      closeCalendar={false}
                      formatShortWeekday={(locale, date) =>
                        ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][
                          date.getDay()
                        ]
                      }
                    />
                    <Button
                      variant="outlined"
                      className="search-calendar-btn"
                      onClick={handleSearchBtn}
                    >
                      Search
                    </Button>
                  </div>
                </Fit>
              )}
            </div>
          </Box>
          <Box className="filters-wrapper">
            {FILTERS.map((f) => (
              <Button
                key={f}
                variant="primary"
                onClick={() => onClickDateFilters(f)}
                className={`${f === activeFilter ? 'active-filter' : ''}`}
              >
                {f}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardHeader;
