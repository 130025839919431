import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useMutation } from '@tanstack/react-query';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Avatar,
  Tooltip,
  Snackbar,
} from '@mui/material';
import { format } from 'date-fns';

import { truncateText } from '@utils';
import { removeTrackData, undoTrackEditData } from '@apis';
import { useUser } from '@context/UserContext';

import ActionsMenu from './ActionsMenu';
import UpdateTrackPopup from '../TracksActions/UpdateTrack';
import RemoveTrack from '../TracksActions/RemoveTrack';
import AddTrackBeforeAfter from '../TracksActions/AddTrackBeforeAfter';
import {
  editTrackStatuses,
  getMessageForStatusOfEdit,
} from '../../../../../constants/editTrackStatuses';
import UndoTrackEditPopup from '../TracksActions/UndoTrackEditPopup';
import PreviousTrackRow from './PreviousTrackRow';
import EditIcon from '../Icons/EditIcon';

const columnHelper = createColumnHelper();
const placeholderImage = '/no-cover-image.png';

const TrackList = ({
  data,
  refetchDashboardData,
  isShowEdits,
  isAllowActions,
  editTrackInfo,
}) => {
  const [isShowUpdateTrack, setIsShowUpdateTrack] = useState(false);
  const [selectedTrackToRemove, setSelectedTrackToRemove] = useState(null);
  const [selectedTrackToUndoEdit, setSelectedTrackToUndoEdit] = useState(null);
  const [selectedTrackToUpdate, setSelectedTrackToUpdate] = useState(null);
  const [selectedTrackToAddNew, setSelectedTrackToAddNew] = useState(null);
  const [serverError, setServerError] = useState('');
  const [hoveredCustomTrackId, setHoveredCustomTrackId] = useState(null);

  const { user } = useUser();

  const dataWithoutRemovedItems = useMemo(
    () => data.filter((row) => row.edit !== editTrackStatuses.REMOVED),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isShowEdits]
  );

  const calculateNewTimeRecorded = (
    previousItem,
    currentItem,
    setSelectedTrackToAddNew
  ) => {
    let newTimeRecorded;

    if (previousItem) {
      newTimeRecorded =
        previousItem.time_recorded +
        (currentItem.original.time_recorded - previousItem.time_recorded) / 2;
    } else {
      newTimeRecorded = currentItem.original.time_recorded - 5 * 60 * 1000;
    }

    setSelectedTrackToAddNew({
      current: currentItem.original,
      newTimeRecorded,
    });
  };

  const getPreviousItemInList = (currentItem, list) => {
    let previousItem = null;
    if (currentItem.index > 0) {
      previousItem = list[currentItem.index - 1];
    }

    return previousItem;
  };

  const columns = [
    columnHelper.accessor('time_recorded', {
      cell: (info) => {
        const currentItem = info.row.original;
        return (
          <Box display="flex" alignItems="center" position="relative">
            {currentItem.custom_track && isShowEdits && (
              <Tooltip
                title={editTrackInfo.track_custom}
                placement="bottom"
                open={hoveredCustomTrackId === currentItem.track_id}
                onOpen={() => setHoveredCustomTrackId(currentItem.track_id)}
                onClose={() => setHoveredCustomTrackId(null)}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#2D323E',
                      color: 'rgba(255, 255, 255, 0.6)',
                      fontSize: '1.1rem',
                      borderRadius: '4px',
                      zIndex: '99999',
                    },
                  },
                }}
              >
                <Box
                  left="-3rem"
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '1.9rem',
                    height: '1.9rem',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(16, 20, 29, 0.4)',
                    },
                  }}
                >
                  <EditIcon />
                </Box>
              </Tooltip>
            )}

            <Box
              sx={{
                whiteSpace: 'nowrap',
                maxWidth: `${250}px`,
              }}
            >
              {format(new Date(info.getValue()), 'HH:mm:ss')} &nbsp;&nbsp;
              {format(new Date(info.getValue()), 'dd/MM/yy')}
            </Box>
          </Box>
        );
      },
      header: 'Time',
    }),
    columnHelper.accessor('cover_image', {
      cell: (info) => {
        return (
          <Avatar
            variant="rounded"
            src={
              info.getValue() && info.getValue() !== '-'
                ? info.getValue()
                : placeholderImage
            }
            alt="Cover"
            onError={(event) => (event.target.src = placeholderImage)}
            sx={{ width: '41px', height: '41px' }}
          />
        );
      },
      header: '',
    }),
    columnHelper.accessor('title', {
      cell: (info) => {
        const text = info.getValue();
        const maxWidth = 265; // Set the max width in pixels or characters

        const maxChars = 50;

        return (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: `${maxWidth}px`,
            }}
          >
            {truncateText(text, maxChars)}
          </Box>
        );
      },
      header: 'Title',
    }),
    columnHelper.accessor('artist', {
      cell: (info) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '270px',
          }}
        >
          {info.getValue()}
        </div>
      ),
      header: 'Artist',
    }),
    columnHelper.accessor('duration', {
      cell: (info) => {
        const durationMs = info.getValue();

        // Check if durationMs is a valid number and handle invalid cases
        if (typeof durationMs !== 'number' || isNaN(durationMs)) {
          return '-'; // or any default text you want to display for invalid values
        }

        const minutes = Math.floor(durationMs / 60000);
        const seconds = Math.floor((durationMs % 60000) / 1000);

        // Construct the duration string
        if (minutes === 0) {
          return `${seconds}s`;
        }
        return `${minutes}m ${seconds}s`;
      },
      header: 'Duration',
    }),
    columnHelper.accessor('genres', {
      cell: (info) => info.getValue().join(', '),
      header: 'Genres',
    }),
    columnHelper.accessor('isrc', {
      cell: (info) => info.getValue(),
      header: 'ISRC',
    }),

    columnHelper.display({
      id: 'actions',
      cell: ({ row: currentItem, table }) => {
        const rowIndex = currentItem.index; // The current row index
        const allRowsData = table.options.data; // The data for all rows

        let nextItem = null;

        if (rowIndex < allRowsData.length - 1) {
          nextItem = allRowsData[rowIndex + 1]; // Get the next item data if it exists
        }
        return (
          <ActionsMenu
            openUpdateTrackPopup={() => {
              setIsShowUpdateTrack(true);
              setSelectedTrackToUpdate(currentItem.original);
            }}
            openRemoveTrackPopup={() => {
              setSelectedTrackToRemove(currentItem.original);
            }}
            openAddBeforeTrackPopup={() => {
              calculateNewTimeRecorded(
                getPreviousItemInList(currentItem, allRowsData),
                currentItem,
                setSelectedTrackToAddNew
              );
            }}
            openAddAfterTrackPopup={() => {
              let newTimeRecorded;

              if (nextItem) {
                newTimeRecorded =
                  nextItem.time_recorded +
                  (currentItem.original.time_recorded -
                    nextItem.time_recorded) /
                    2;
              } else {
                newTimeRecorded =
                  currentItem.original.time_recorded + 5 * 60 * 1000;
              }
              setSelectedTrackToAddNew({
                current: currentItem.original,
                newTimeRecorded,
              });
            }}
            openUndoEditPopup={() => {
              if (currentItem.original.edit === editTrackStatuses.UPDATED) {
                setSelectedTrackToUndoEdit(currentItem.original.previous_track);
              } else {
                setSelectedTrackToUndoEdit(currentItem.original);
              }
            }}
            isDisabled={!isAllowActions}
            trackStatus={currentItem.original.edit}
            isPreviousTrack={false}
          />
        );
      },
    }),
  ];

  const { id: eventId } = useParams();
  const table = useReactTable({
    data: isShowEdits ? data : dataWithoutRemovedItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const auth = getAuth();

  const { mutate: removeTrack, isPending: isLoadingRemoveTrack } = useMutation({
    mutationFn: removeTrackData,
    onSuccess: (response) => {
      if (
        response &&
        response.data &&
        response.data.status === 'Track removed' &&
        response.status === 200
      ) {
        refetchDashboardData();
        setSelectedTrackToRemove(null);
      } else {
        setServerError(response.data.status);
      }
    },

    onError: (err) => {
      console.log('ERROR');
    },
  });

  const { mutate: undoEditTrackMutate, isPending: isLoadingUndoEdit } =
    useMutation({
      mutationFn: undoTrackEditData,
      onSuccess: (response) => {
        if (
          response &&
          response.data &&
          response.data.status === 'Track edit undone' &&
          response.status === 200
        ) {
          refetchDashboardData();
          setSelectedTrackToUndoEdit(null);
        } else {
          setServerError(response.data.status);
        }
      },

      onError: (err) => {
        console.log('ERROR');
      },
    });

  const handleSubmitRemoveTrack = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            eventId,
            timeRecorded: selectedTrackToRemove.time_recorded,
            trackId: selectedTrackToRemove.track_id,
          };
          await removeTrack(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };

  const handleSubmitUndoEditTrack = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            eventId,
            timeRecorded: selectedTrackToUndoEdit.time_recorded,
            trackId: selectedTrackToUndoEdit.track_id,
          };
          await undoEditTrackMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };
  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableCell
                key={header.id}
                sx={{
                  paddingLeft: index === 0 ? '6rem' : 'default',
                  paddingRight:
                    index === table.getHeaderGroups().length - 1
                      ? '6rem'
                      : 'default',
                  py: '0.5rem',
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <>
      <Box
        borderRadius={'0.8rem'}
        py="5rem"
        px="0.1rem"
        bgcolor={'background.section'}
      >
        <TableContainer>
          <Table
            sx={{
              height: '100%',
              borderSpacing: '0 12px',
              borderCollapse: 'separate',
              border: 'transparent',
              thead: {
                borderSpacing: '0',
                borderCollapse: 'collapse',
              },
            }}
            style={{ paddingLeft: '0.4rem', paddingRight: '0.4rem' }}
          >
            {renderHeader()}
            <TableBody>
              {table.getRowModel().rows.map((row) => {
                const editValue = row.original.edit;
                const hasPreviousTrack = row.original.previous_track !== null;
                let borderColor = 'transparent';

                if (editValue === editTrackStatuses.ADDED) {
                  borderColor = '#009E10';
                } else if (editValue === editTrackStatuses.REMOVED) {
                  borderColor = '#B31037';
                } else if (editValue === editTrackStatuses.UPDATED) {
                  borderColor = '#F4BD31';
                }

                return (
                  <React.Fragment key={row.id}>
                    {hasPreviousTrack && isShowEdits && (
                      <PreviousTrackRow
                        row={row}
                        borderColor={borderColor}
                        actionsMenuProps={{
                          undoEditHandler: () => {
                            setSelectedTrackToUndoEdit(
                              row.original.previous_track
                            );
                          },
                          addBeforeTrackHandler: () => {
                            calculateNewTimeRecorded(
                              getPreviousItemInList(row, table.options.data),
                              row,
                              setSelectedTrackToAddNew
                            );
                          },
                          isAllowActions,
                        }}
                        editTrackInfo={editTrackInfo}
                      />
                    )}

                    <TableRow hover>
                      {row.getVisibleCells().map((cell, cellIndex) => {
                        // Apply Tooltip only to non-action cells
                        const cellContent = (
                          <TableCell
                            key={cell.id}
                            sx={{
                              fontSize: '1.4rem !important',
                              paddingLeft:
                                cellIndex === 0 ? '5.7rem' : 'inherit',
                              paddingRight:
                                cellIndex === row.getVisibleCells().length - 1
                                  ? '5.7rem'
                                  : 'inherit',
                              py: editValue === 'none' ? 0 : '1px',
                              borderTop:
                                isShowEdits &&
                                editValue !== 'none' &&
                                editValue !== editTrackStatuses.UPDATED
                                  ? `1px solid ${borderColor}`
                                  : 'none',
                              borderBottom:
                                isShowEdits && editValue !== 'none'
                                  ? `1px solid ${borderColor} !important`
                                  : 'none',
                              borderRadius:
                                cellIndex === 0
                                  ? editValue === editTrackStatuses.UPDATED
                                    ? '0 0 0 4px'
                                    : '4px 0 0 4px'
                                  : cellIndex ===
                                    row.getVisibleCells().length - 1
                                  ? editValue === editTrackStatuses.UPDATED
                                    ? '0 0 4px 0'
                                    : '0 4px 4px 0'
                                  : '0',
                              borderLeft:
                                cellIndex === 0
                                  ? `1px solid ${
                                      isShowEdits ? borderColor : 'transparent'
                                    }`
                                  : 'none',
                              borderRight:
                                cellIndex === row.getVisibleCells().length - 1
                                  ? `1px solid ${
                                      isShowEdits ? borderColor : 'transparent'
                                    }`
                                  : 'none',
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );

                        // Only wrap with Tooltip if the cell is not an "actions" cell
                        return cell.column.id !== 'actions' ? (
                          <Tooltip
                            key={cell.id}
                            title={getMessageForStatusOfEdit(
                              editValue,
                              editTrackInfo
                            )}
                            followCursor
                            placement="bottom"
                            disableHoverListener={
                              !isShowEdits || editValue === 'none'
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: '#2D323E',
                                  color: 'rgba(255, 255, 255, 0.6)',
                                  fontSize: '1.1rem',
                                  borderRadius: '4px',
                                  display: !!hoveredCustomTrackId
                                    ? 'none !important'
                                    : 'block',
                                },
                              },
                            }}
                          >
                            {cellContent}
                          </Tooltip>
                        ) : (
                          cellContent
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {isShowUpdateTrack && (
        <UpdateTrackPopup
          open={isShowUpdateTrack}
          handleClose={() => {
            setIsShowUpdateTrack(false);
            setSelectedTrackToUpdate(null);
          }}
          refetchDashboard={refetchDashboardData}
          selectedTrackToUpdate={selectedTrackToUpdate}
          eventId={eventId}
        />
      )}

      {selectedTrackToRemove && (
        <RemoveTrack
          open={!!selectedTrackToRemove}
          handleClose={() => setSelectedTrackToRemove(null)}
          handleSubmit={handleSubmitRemoveTrack}
          loading={isLoadingRemoveTrack}
          selectedTrackToUpdate={selectedTrackToRemove}
        />
      )}

      {selectedTrackToAddNew && (
        <AddTrackBeforeAfter
          open={!!selectedTrackToAddNew}
          handleClose={() => setSelectedTrackToAddNew(null)}
          selectedTrack={selectedTrackToAddNew}
          eventId={eventId}
          refetchDashboard={refetchDashboardData}
        />
      )}

      {selectedTrackToUndoEdit && (
        <UndoTrackEditPopup
          open={!!selectedTrackToUndoEdit}
          handleClose={() => setSelectedTrackToUndoEdit(null)}
          handleSubmit={handleSubmitUndoEditTrack}
          loading={isLoadingUndoEdit}
        />
      )}

      <Box className="error-alert">
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
          open={!!serverError && serverError !== ''}
          onClose={() => {
            setServerError('');
          }}
          message={serverError}
        />
      </Box>
    </>
  );
};

export default TrackList;
