import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from '@mui/material';
import EmptyContent from '../EmptyContent';

const placeholderImage = '/no-cover-image.png';

const TopTracks = ({ tracks }) => {
  if (tracks.length === 0 || !tracks) {
    return <EmptyContent text="There are no top tracks found in the group." />;
  }

  const handleImageError = (event) => {
    event.target.src = placeholderImage; // Fallback to placeholder image
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.section',
        borderRadius: '0.8rem',

        height: '39rem',
        overflow: 'auto',
        py: '4.2rem',
      }}
    >
      <List sx={{ p: 0 }}>
        {tracks.map((item, index) => (
          <ListItem
            key={item.spotify_id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 0,
              mb: tracks.length - 1 === index ? 0 : '0.8rem',
              px: '6rem',

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.09)',
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ flexGrow: 1, minWidth: 0 }}
            >
              <Typography variant="h5" sx={{ mr: 2, flexShrink: 0 }}>
                {index + 1}.
              </Typography>
              <ListItemAvatar sx={{ minWidth: 41 }}>
                <Avatar
                  variant="rounded"
                  src={
                    item.cover_image && item.cover_image !== '-'
                      ? item.cover_image
                      : placeholderImage
                  }
                  alt={item.title}
                  sx={{ width: 41, height: 41 }}
                  onError={handleImageError} // Handle failed image loading
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" color="white" noWrap>
                    {item.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="secondary.main" noWrap>
                    {item.artist}
                  </Typography>
                }
                sx={{
                  ml: '24px',
                  minWidth: '300px',
                  flexGrow: 1,
                  flexBasis: '80%',
                  maxWidth: '100%',
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ minWidth: 100 }}
            >
              <Typography variant="body2" color="secondary.main" noWrap>
                {item.genres.length > 0 ? item.genres.join(', ') : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ minWidth: 80, justifyContent: 'flex-end' }}
            >
              <Typography variant="h5" color="secondary.main" noWrap>
                {item.count}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TopTracks;
