import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { Box, Container, Grid, Button } from '@mui/material';

import {
  useEventDashboardData,
  exportEventsData,
  useApproveTrackDataMutation,
} from '@apis';
import { DATA_ERRORS } from '@configs';
import { useUser } from '@context/UserContext';
import { getFilenameFromContentDisposition, downloadBlob } from '@utils';
import { LoadingLogo } from '@components';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopGenres from './components/v2/TopGenres';
import TrackList from './components/v2/TrackList';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';
import LocationMap from './components/v2/LocationMap';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';
import { SectionSkeleton } from '@components/Skeletons/SectionSkeleton';
import ServerErrorAlert from './components/v2/ServerErrorAlert';
import ActionsPopup from './components/v2/ActionsPopup';
import ShowEditsSwitchButton from './components/v2/ShowEditsSwitchButton';
import ApproveTrackListSwitchBtn from './components/v2/ApproveTrackListSwitchBtn';

export const EventDashboard = () => {
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [showExportPrompt, setShowExportPrompt] = useState(false);
  const [isShowEditsOnTracksList, setIsShowEditsOnTracksList] = useState(false);
  const [isApproveTrackList, setIsApproveTrackList] = useState(false);

  const auth = getAuth();
  const { user, isLoading: isLoadingUserInfo } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const { eventId, associatedDevice } = location.state || {};

  const {
    fetchDashboardData,
    isLoadingDashboardData,
    serverError,
    data: dashboardData,
  } = useEventDashboardData();
  const { mutateAsync: exportEventsMutate, isPending: isExportEventsLoading } =
    useMutation({
      mutationFn: exportEventsData,
      onSuccess: (response) => {
        let contentType = response.headers?.['content-type'];
        let contentDisposition = response.headers?.['content-disposition'];

        let filename;
        if (contentType === 'text/csv; charset=utf-8') {
          filename =
            getFilenameFromContentDisposition(contentDisposition) ||
            'events.csv';
        } else if (contentType === 'application/zip') {
          filename = 'events.zip';
        } else {
          console.warn('Unexpected content type:', contentType);
          filename = 'downloaded_file'; // Fallback filename
        }

        // Create a blob with the correct content type
        const blob = new Blob([response.data], {
          type: contentType || 'application/octet-stream',
        });

        // Download the file
        downloadBlob(blob, filename);

        setShowExportPrompt(false);
      },
      onError: (error) => {
        console.error('Export failed:', error);
      },
    });

  const { mutate: approveTrackMutate } = useApproveTrackDataMutation();

  const isUserHost = user?.user_id === dashboardData?.event_host_id;
  const hasNoEditInTrack = !dashboardData?.section_3?.object?.some(
    (item) => item.edit !== 'none'
  );

  useEffect(() => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (dashboardData) {
      if (!isLoadingDashboardData) {
        setIsLoadingDashboard(false);
      }
      if (eventId) {
        const breadcrumbDataWithDeviceName = associatedDevice
          ? [
              { key: 1, label: 'Home', href: '/' },
              {
                key: 2,
                label: associatedDevice.deviceName,
                href: associatedDevice.deviceUrl,
              },
              {
                key: 3,
                label: moment(dashboardData?.event_date).format('Do MMMM YYYY'),
              },
            ]
          : [
              { key: 1, label: 'Home', href: '/' },
              {
                key: 2,
                label: moment(dashboardData?.event_date).format('Do MMMM YYYY'),
              },
            ];
        updateBreadcrumbs(breadcrumbDataWithDeviceName);
      } else {
        updateBreadcrumbs([{ key: 1, label: 'Home' }]);
      }

      setIsApproveTrackList(dashboardData.approved);

      if (DATA_ERRORS.includes(dashboardData.status)) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      }
      return;
    }

    if (serverError) {
      setIsLoadingDashboard(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData, serverError]);

  useEffect(() => {
    if (!user) {
      return;
    }
    getRefreshedTokenAndUpdateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getRefreshedTokenAndUpdateUser = () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (!dashboardData) {
        setIsLoadingDashboard(true);
      }

      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);

          const params = {
            user_id: user?.user_id,
            access_token: idToken,
            event_id: eventId,
          };
          fetchDashboardData(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleExportEvents = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,

            eventIds: [eventId],
          };
          await exportEventsMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const handleToggleAndApproveTrackListRequest = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);

          const newApproveStatus = !isApproveTrackList;
          setIsApproveTrackList(newApproveStatus);

          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            eventId,
            approve: newApproveStatus,
          };
          await approveTrackMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  return (
    <>
      {((isLoadingDashboard && !dashboardData) ||
        isLoadingUserInfo ||
        isLoadingDashboardData) && <LoadingLogo />}

      <Box pb="9rem">
        <Container maxWidth="xl">
          <DashboardHeader
            hasFilterOptions={false}
            dashboardType={dashboardTypes.event}
            eventDate={dashboardData?.event_date}
            hasLoading={isLoadingDashboard}
          />

          {serverError ? (
            <Box mt="5.4rem" height="100%">
              <ServerErrorAlert message={serverError} />
            </Box>
          ) : (
            <>
              <Box mt={'54px'}>
                {(isLoadingDashboard || isLoadingDashboardData) && (
                  <SectionSkeleton height="20rem" />
                )}

                {!isLoadingDashboard && dashboardData?.metrics && (
                  <>
                    <SectionHeading
                      headingText={dashboardData?.metrics.title}
                      tooltipText={dashboardData?.metrics.info}
                    />
                    <MetricCount
                      metrics={Object.values(dashboardData.metrics.object)}
                    />
                  </>
                )}
              </Box>
              <Box mt="5rem">
                <Grid container spacing={'4rem'}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {dashboardData?.section_1 && (
                      <>
                        <SectionHeading
                          headingText={dashboardData?.section_1.title}
                          tooltipText={dashboardData?.section_1.info}
                        />

                        {dashboardData?.section_1.object.length === 0 ? (
                          <EmptyContent text="There are no genres in the list." />
                        ) : (
                          <TopGenres data={dashboardData?.section_1.object} />
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {!isLoadingDashboard && dashboardData?.section_2 && (
                      <>
                        <SectionHeading
                          headingText={dashboardData?.section_2.title}
                          tooltipText={dashboardData?.section_2.info}
                        />

                        <LocationMap data={dashboardData?.section_2.object} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box mt="5rem">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {isLoadingDashboard && <SectionSkeleton />}

                    {!isLoadingDashboard && dashboardData?.section_3.object && (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <SectionHeading
                            headingText={dashboardData?.section_3.title}
                            tooltipText={dashboardData?.section_3.info}
                          />

                          <Box mb="2rem" display="flex" alignItems="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              mr={'3.2rem'}
                            >
                              <ApproveTrackListSwitchBtn
                                tooltipInfo={
                                  dashboardData?.approve_track_list_info
                                    ?.toggle_info
                                }
                                isApprove={isApproveTrackList}
                                setIsApprove={
                                  handleToggleAndApproveTrackListRequest
                                }
                                isDisabled={!isUserHost}
                                toggleBtnTooltipText={
                                  !isUserHost
                                    ? dashboardData?.approve_track_list_info
                                        ?.only_host
                                    : null
                                }
                              />
                            </Box>
                            <ShowEditsSwitchButton
                              tooltipInfo={
                                dashboardData?.edit_track_info?.toggle_info
                              }
                              isShow={isShowEditsOnTracksList}
                              setIsShow={() =>
                                setIsShowEditsOnTracksList(
                                  !isShowEditsOnTracksList
                                )
                              }
                              isDisabled={hasNoEditInTrack}
                              toggleBtnTooltipText={
                                hasNoEditInTrack
                                  ? dashboardData?.edit_track_info?.no_edits
                                  : null
                              }
                            />
                            <Button
                              variant="outlined"
                              onClick={() => setShowExportPrompt(true)}
                              sx={{ marginLeft: '3.2rem' }}
                            >
                              Export Event
                            </Button>
                          </Box>
                        </Box>
                        <TrackList
                          data={dashboardData?.section_3.object || []}
                          refetchDashboardData={getRefreshedTokenAndUpdateUser}
                          isShowEdits={isShowEditsOnTracksList}
                          isAllowActions={
                            user.user_id === dashboardData?.event_host_id
                          }
                          editTrackInfo={dashboardData?.edit_track_info}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Box>

      <ActionsPopup
        open={showExportPrompt}
        handleClose={() => setShowExportPrompt(false)}
        handleSubmit={handleExportEvents}
        loading={isExportEventsLoading}
        headingText="Export event"
        loadingText="Exporting..."
        submitBtnText="Export"
      />
    </>
  );
};
