import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from '@mui/material';
import { formatStartTime } from '@utils';

import LiveBadge from '../LiveBadge';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('start_time', {
    cell: (info) => {
      const live = info.row.original.live;
      const formattedDate = formatStartTime(info.getValue());

      return (
        <Box display="flex" alignItems="center">
          <span style={{ marginRight: '1rem' }}>{formattedDate}</span>
          {live && <LiveBadge />}
        </Box>
      );
    },
  }),
  columnHelper.accessor('device_name', {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('location', {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('event_length', {
    cell: (info) => {
      const totalMinutes = info.getValue() / 60000;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);

      return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
    },
  }),
];

const LatestEvents = ({ data }) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box
      sx={{
        bgcolor: 'background.section',
        borderRadius: '0.8rem',
        height: '39rem',
        py: '1.7rem',
      }}
    >
      <TableContainer sx={{ borderRadius: '0.8rem' }}>
        <Table sx={{ height: '100%' }}>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={() =>
                  navigate(`/event/${row.original.event_id}`, {
                    state: {
                      eventId: row.original.event_id,
                      deviceName: null,
                    },
                  })
                }
                sx={{
                  cursor: 'pointer',
                }}
                hover
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      paddingLeft: index === 0 ? '3rem' : 'default',
                      paddingRight:
                        index === row.getVisibleCells().length - 1
                          ? '3rem'
                          : 'default',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LatestEvents;
