import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS } from '@configs';

const fetchEventDashboardData = async (dataa) => {
  const response = await axiosClient().post(
    API_ENDPOINTS.EVENT_DASHBOARD_DATA.API,
    dataa
  );
  return response;
};

export const useEventDashboardData = () => {
  const [data, setData] = useState(null); // Local state to store data
  const [serverError, setServerError] = useState(null);

  const mutation = useMutation({
    mutationFn: fetchEventDashboardData,
    onSuccess: (response) => {
      if (response.status === 200) {
        setData(response.data.data); // Update local data on success
        setServerError(null);
      } else {
        setServerError(response.data.status);
      }
    },
    onError: (error) => {
      setServerError(error.message);
    },
  });

  const fetchDashboardData = async (dataa) => {
    try {
      await mutation.mutateAsync(dataa);
    } catch (error) {
      console.error('Error fetching event dashboard data:', error);
    }
  };

  return {
    data, // Return local data instead of mutation.data
    isLoadingDashboardData: mutation.isLoading,
    error: mutation.error,
    fetchDashboardData,
    serverError,
    mutation,
  };
};
