import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box } from '@mui/material';
import { useUser } from '@context/UserContext';
import ActionsPopup from '../ActionsPopup';
import SearchTrack from './SearchTrack';
import { updateTrackData } from '@apis';
import AddCustomTrackForm from './AddCustomTrackForm';
import TrackItem from './TrackItem';

const UpdateTrackPopup = ({
  open,
  handleClose,
  selectedTrackToUpdate,
  eventId,
  refetchDashboard,
}) => {
  const [isShowAddCustomTrack, setIsShowAddCustomTrack] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [addedCustomTrack, setAddedCustomTrack] = useState({
    title: '',
    artists: '',
  });
  const [addedFoundTrack, setAddedFoundTrack] = useState({
    trackId: '',
    title: '',
    artists: '',
    coverImage: '',
  });

  const auth = getAuth();
  const { user } = useUser();

  const { mutate: updateTrackMutate, isPending: isLoadingOnUpdateTrack } =
    useMutation({
      mutationFn: updateTrackData,
      onSuccess: (response) => {
        if (response && response.data) {
          refetchDashboard();
          handleClose();
        }
      },

      onError: (err) => {
        console.log('ERROR');
      },
    });

  const invalidAddedCustomTrack =
    addedCustomTrack.title === '' || addedCustomTrack.artists === '';
  const shouldDisplayCustomTrack =
    !invalidAddedCustomTrack && !isShowAddCustomTrack && isAddButtonClicked;
  const shouldDisplayFoundTrack =
    addedFoundTrack.title !== '' &&
    addedFoundTrack.artists !== '' &&
    !isShowAddCustomTrack;

  const handleSubmitUpdateTrack = async () => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const hasCustomTrack = addedCustomTrack.title !== '';
          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            eventId,

            currentTimeRecorded: selectedTrackToUpdate.time_recorded,
            newTrackId: hasCustomTrack ? null : addedFoundTrack.trackId,
            currentTrackId: selectedTrackToUpdate.track_id,
            customTrack: hasCustomTrack ? addedCustomTrack : null,
          };
          await updateTrackMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };

  const resetFoundTrack = () => {
    setAddedFoundTrack({
      title: '',
      artists: '',
      coverImage: '',
      trackId: '',
    });
  };

  const renderTrackItem = (title, artist, coverImage, timeRecorded) => (
    <Box mt="3.3rem">
      <TrackItem
        trackTypeInfo="New Track"
        title={title}
        artist={artist}
        coverImage={coverImage}
        duration={timeRecorded}
      />
    </Box>
  );

  const onAddButtonSubmit = () => {
    setIsShowAddCustomTrack(false);
    setIsAddButtonClicked(true);
    resetFoundTrack();
  };

  const isDisabledSubmit = () => {
    if (isShowAddCustomTrack) {
      return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
    } else {
      if (isAddButtonClicked) {
        return addedCustomTrack.title === '' || addedCustomTrack.artists === '';
      } else {
        return addedFoundTrack.title === '' || addedFoundTrack.artists === '';
      }
    }
  };

  return (
    <ActionsPopup
      open={open}
      handleClose={() => {
        if (isShowAddCustomTrack) {
          setIsShowAddCustomTrack(false);
        } else {
          resetFoundTrack();
          setAddedCustomTrack({ title: '', artists: '' });
          setIsAddButtonClicked(false);
          handleClose();
        }
      }}
      handleSubmit={() => {
        if (isShowAddCustomTrack) {
          onAddButtonSubmit();
        } else {
          handleSubmitUpdateTrack();
        }
      }}
      loading={isLoadingOnUpdateTrack}
      headingText={isShowAddCustomTrack ? 'Custom Track' : 'Update Track'}
      loadingText="Updating..."
      submitBtnText={isShowAddCustomTrack ? 'Add' : 'Update'}
      disabledSubmit={isDisabledSubmit()}
    >
      {selectedTrackToUpdate && !isShowAddCustomTrack && (
        <Box mb="4.3rem">
          <TrackItem
            trackTypeInfo="Current Track"
            title={selectedTrackToUpdate.title}
            artist={selectedTrackToUpdate.artist}
            coverImage={selectedTrackToUpdate.cover_image}
            duration={selectedTrackToUpdate.time_recorded}
          />
        </Box>
      )}

      {isShowAddCustomTrack ? (
        <AddCustomTrackForm
          addedTrack={addedCustomTrack}
          setAddedTrack={setAddedCustomTrack}
        />
      ) : (
        <SearchTrack
          onClickAddCustomTrack={() => setIsShowAddCustomTrack(true)}
          updateFoundTrack={(track) => {
            setAddedFoundTrack({
              title: track.title,
              artists: track.artists,
              coverImage: track.coverImage,
              trackId: track.trackId,
            });
            setAddedCustomTrack({ title: '', artists: '' });
            setIsAddButtonClicked(false);
          }}
        />
      )}
      <>
        {shouldDisplayCustomTrack &&
          renderTrackItem(
            addedCustomTrack.title,
            addedCustomTrack.artists,
            addedCustomTrack.coverImage,
            selectedTrackToUpdate.time_recorded
          )}

        {shouldDisplayFoundTrack &&
          renderTrackItem(
            addedFoundTrack.title,
            addedFoundTrack.artists,
            addedFoundTrack.coverImage,
            selectedTrackToUpdate.time_recorded
          )}
      </>
    </ActionsPopup>
  );
};

export default UpdateTrackPopup;
