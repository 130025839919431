import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Edit } from '@mui/icons-material';

import { searchTracksData } from '@apis';
import { useUser } from '@context/UserContext';
import LoadingSpinner from '../LoadingSpinner';

const SearchTrack = ({ onClickAddCustomTrack, updateFoundTrack }) => {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isShowResults, setIsShowResults] = useState(false);

  const auth = getAuth();
  const { user } = useUser();

  const searchContainerRef = useRef(null);

  const {
    mutate: searchTrackMutate,
    isPending: isLoadingSearchTrack,
    data: searchTrackResponse,
  } = useMutation({
    mutationFn: searchTracksData,
    onSuccess: (response) => {},
    onError: (err) => {
      console.log('ERROR');
    },
  });

  const handleSearchTracks = async (searchQuery) => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const idToken = await authUser.getIdToken(false);
          localStorage.setItem('user_access_token', idToken);
          const params = {
            userId: user?.user_id,
            accessToken: idToken,
            searchQuery,
          };
          await searchTrackMutate(params);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
      }
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    setIsShowResults(value.length > 0);
    handleSearchTracks(value);
  };

  const handleSearchClick = () => {
    console.log('Search button clicked with query:', query);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleClear = () => {
    setQuery('');
    setIsShowResults(false);
  };

  // Close results if click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsShowResults(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        width: '100%',
        margin: '0 auto',
        color: '#fff',
        position: 'relative',
      }}
      ref={searchContainerRef}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for new track"
          value={query}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={() => {
            handleBlur();
          }}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoadingSearchTrack ? (
                  <Box height={'1.8rem'} display="flex">
                    <LoadingSpinner />
                  </Box>
                ) : (
                  <IconButton
                    onClick={query.length ? handleClear : handleSearchClick}
                    sx={{ padding: 0 }}
                  >
                    {isFocused || query.length ? (
                      <CloseIcon
                        sx={{ color: '#E0E0E0', width: 20, height: 20 }}
                      />
                    ) : (
                      <img
                        src="/search-icon.svg"
                        alt="Search Icon"
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            borderWidth: '1px !important',
            fontSize: '1.2rem',
            borderColor: '#E0E0E0 !important',
            input: {
              color: '#fff',
              fontSize: '1.2rem',
              padding: '1.2rem 1.6rem',
              lineHeight: '1.6rem',
              height: 'auto',
              borderWidth: '1px !important',
              borderColor: '#E0E0E0 !important',
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E0E0E0 !important',
              borderWidth: '1px !important',
            },
            '& fieldset': {
              borderColor: '#757575',
              borderRadius: '4px',
              borderBottomLeftRadius: isShowResults ? 0 : '4px',
              borderBottomRightRadius: isShowResults ? 0 : '4px',
              borderBottom: isShowResults ? 'none' : '1px solid #757575',
              borderWidth: '1px !important',
            },
          }}
        />
      </Box>

      {isShowResults && (
        <List
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 10,
            backgroundColor: 'background.section',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            border: '1px solid #E0E0E0',
            borderTop: 'none',
            borderWidth: '1px',
            paddingBottom: '1.6rem',
            paddingTop: '1.6rem',
          }}
        >
          {searchTrackResponse &&
            searchTrackResponse?.data?.data?.length > 0 &&
            searchTrackResponse?.data?.data?.map((result, index) => (
              <ListItem
                key={result.track_id}
                sx={{
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'background.baseHover',
                    cursor: 'pointer',
                  },
                }}
                type="button"
                onClick={() => {
                  updateFoundTrack({
                    trackId: result.track_id,
                    title: result.title,
                    artists: result.artist,
                    coverImage: result.cover_image,
                  });
                  setQuery('');
                  setIsShowResults(false);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={result.title}
                    src={
                      result.cover_image && result.cover_image !== 'undefined'
                        ? result.cover_image
                        : '/no-cover-image.png'
                    }
                    sx={{
                      width: '4.1rem',
                      height: '4.1rem',
                      borderRadius: '4px',
                    }}
                  />
                </ListItemAvatar>

                <ListItemText
                  sx={{ margin: 0, color: '#E0E0E0', maxWidth: '25rem' }}
                  primary={
                    <Typography
                      sx={{
                        fontSize: '1.4rem',
                        fontWeight: 400,
                        lineHeight: '1.8rem',
                        marginBottom: '0.6rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {result.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: '1.4rem',
                        fontWeight: 400,
                        opacity: 0.9,
                        lineHeight: '1.6rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {result.artist}
                    </Typography>
                  }
                />
              </ListItem>
            ))}

          <ListItem
            sx={{
              paddingTop: '14.9px',
              paddingBottom: '14.9px',
              paddingLeft: '16px',
              paddingRight: '16px',
              '&:hover': {
                backgroundColor: 'background.baseHover',
                cursor: 'pointer',
              },
            }}
            type="button"
            onClick={() => {
              onClickAddCustomTrack();
              setIsShowResults(false);
            }}
          >
            <Box
              edge="start"
              sx={{
                width: '19.2px',
                height: '19.2px',
                display: 'flex',
                alignItems: 'center',
                marginRight: '8px',
                color: '#fff',
              }}
            >
              <Edit />
            </Box>
            <Typography
              sx={{
                color: '#E0E0E0',
                fontSize: '1.4rem',
                fontWeight: 400,
                lineHeight: '1.8rem',
              }}
            >
              Can’t find it? Add a new one
            </Typography>
          </ListItem>
        </List>
      )}
    </Box>
  );
};

export default SearchTrack;
