import { format, isToday, isYesterday } from 'date-fns';

export const formatStartTime = (date) => {
  const parsedDate = new Date(date);

  const time = format(parsedDate, 'HH:mm');

  if (isToday(parsedDate)) {
    return `${time} Today`;
  } else if (isYesterday(parsedDate)) {
    return `${time} Yesterday`;
  } else {
    return `${time} ${format(parsedDate, 'do MMMM yyyy')}`;
  }
};
