import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },

  palette: {
    primary: {
      main: '#757575', // primary-grey-color
    },
    secondary: {
      main: '#e0e0e0', // secondary-grey-color
    },
    background: {
      default: '#0f0f0f', // background color of the body
      section: '#1B2130',
      tooltip: '#2D323E',
      baseHover: 'rgba(16, 20, 29, 0.4)',
    },
    error: {
      main: '#cd1c35', // error-color
    },
    neon: {
      main: '#ff4a4a', // secondary-neon-color
    },
    text: {
      primary: '#ffffff', // primary text color
    },
  },

  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 700,
    },
    h4: {
      fontWeight: 400,
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.6rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    body1: {
      fontWeight: 500,
      fontSize: '2rem',
    },
    body2: {
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    overline: {
      fontWeight: 300,
    },

    subtitle1: {
      fontWeight: 500,
      fontSize: '1.4rem',
      color: '#fff',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '1.4rem',
      color: '#fff',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '40px !important', // Set left padding
          paddingRight: '40px !important', // Set right padding
        },
        maxWidthXl: {
          maxWidth: '1440px', // Set maxWidth for xl to 1440px
          '@media (min-width: 1440px)': {
            maxWidth: '1440px',
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '62.5%',
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: '#1B2130',
          color: '#E0E0E0',
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontSize: '1.6rem',
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontWeight: 400,
            fontSize: '1.4rem',
            borderBottom: 'none',
            color: '#e0e0e0',
          },

          '& .MuiTableRow-root': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
          },

          '& .MuiTableRow-hover:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09) !important',
          },

          '& .Mui-selected': {
            backgroundColor: '#10141D !important',
          },

          '& .Mui-selected:hover': {
            backgroundColor: '#10141D !important',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255, 255, 255, 0.06)',
          padding: '2rem',
          borderBottom: 'none',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#757575',
          '&.Mui-checked': {
            color: '#757575',
            '& .MuiSvgIcon-root': {
              '& path': {
                fill: '#E0E0E0',
              },
              borderRadius: '4px',
            },
          },
          '&.Mui-disabled': {
            color: '#75757580',
          },
          '& .MuiSvgIcon-root': {
            width: '2.3rem',
            height: '2.3rem',
            borderRadius: '4px',
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          fontWeight: 400,
          lineHeight: 1,
          borderRadius: '0.4rem',
          textTransform: 'capitalize',
          color: '#fff',
        },
        outlinedPrimary: {
          padding: '1.3rem 3.3rem',
          position: 'relative',
          color: '#FFFFFF',
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          textTransform: 'capitalize',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            borderRadius: 'inherit',
            padding: '1px',
            background: 'linear-gradient(45deg, #FF1A1A, #FD8A50)',
            '-webkit-mask':
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            '-webkit-mask-composite': 'xor',
            'mask-composite': 'exclude',
          },
          '&:hover': {
            border: '1px solid transparent',
            '&::before': {
              background: 'linear-gradient(45deg, #FF1A1A, #FD8A50)',
            },
          },
          '&.Mui-disabled': {
            color: '#FFFFFF',
            opacity: 0.5,
          },
        },
        sizeSmall: {
          fontSize: '1rem',
          padding: '1rem 2.5rem',
          // borderRadius: '0.3rem',
        },
        sizeMedium: {
          fontSize: '1.2rem',
          padding: '1.2rem 3rem',
          // borderRadius: '0.4rem',
        },
        sizeLarge: {
          fontSize: '1.4rem',
          padding: '1.5rem 3.5rem',
          borderRadius: '0.8rem',
          fontWeight: 700,
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
